import config from "../config";

function login(address) {
  window.sessionStorage.setItem(config["session_storage_param"]["identity_address"], address);
}

function logout() {
  window.sessionStorage.removeItem(config["session_storage_param"]["identity_address"]);
}

function isLogin() {
  if (window.sessionStorage.getItem(config["session_storage_param"]["identity_address"])) {
    return true;
  }
  return false;
}

export { isLogin, login, logout };
