import defaultConfig from '../src/config/default.json';
import development from '../src/config/development.json';
import production from '../src/config/production.json';

function setEnvironmentFile() {
    const environment = process.env.NODE_ENV;
    switch (environment) {
        case 'development':
            return development;
        case 'production':
            return production;
        default:
            return development;
    }
}

function setVariables() {
    let config = defaultConfig;
    let envFile = setEnvironmentFile();
    for (let [key, value] of Object.entries(envFile)) {
        config[key] = value;
    }
    config["google_client_id"] = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    config["microsoft_config"]["auth"]["clientId"] = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
    config["microsoft_config"]["auth"]["redirectUri"] = config["routes"]["hostname"];
    config["github_config"]["redirectUri"] = config["routes"]["hostname"] + "/authenticate";
    return config;
}

const config = setVariables();
export default config;
