import base64url from "base64url";

import config from "../config" 

function publicKeyCredentialToJSON(pubKeyCred) {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};

    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj;
  }

  return pubKeyCred;
}

async function sendPublicKeyCredentialAssertionToServer(
  publicKeyCredential,
  address,
  providerURL
) {
  return await (async () => {
    const rawResponse = await fetch(
      config["routes"]["server"] + "/webauthn/verify-assertion",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webAuthnResponse: publicKeyCredentialToJSON(publicKeyCredential),
          address: address,
          providerURL: providerURL,
        }),
      }
    );
    const content = await rawResponse.json();
    return content;
  })();
}

export { sendPublicKeyCredentialAssertionToServer };
