import { createStore } from "redux";

const initialState = {
  animationStep: 0,
  identity: null,
  provider: "",
  signer: "",
  guideMode: false,
  location: "",
  helperState: "start",
  nextPage: "",
  ensContract: "",
};

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case "setIdentity":
      return { ...state, identity: action.identity };
    case "setProvider":
      return { ...state, provider: action.provider };
    case "setSigner":
      return { ...state, signer: action.signer };
    case "setLocation":
      return {
        ...state,
        location: action.location,
      };
    case "setHelperState":
      return {
        ...state,
        helperState: action.helperState,
      };
    case "setGuideMode":
      return {
        ...state,
        guideMode: action.guideMode,
      };
    case "setNextPage":
      return {
        ...state,
        nextPage: action.nextPage,
      };
    case "setAnimationStep":
      return {
        ...state,
        animationStep: action.animationStep,
      };
    case "setEnsContract":
      return {
        ...state,
        ensContract: action.ensContract,
      };
    default:
      return state;
  }
}
let store = createStore(
  globalReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
