import { ethers } from "ethers";
import { IdentitySDK } from "@onchain-id/identity-sdk";
import config from "../config";

async function isWorkingAtInTech(identity, provider) {
  const rawResponse = await fetch(
    config["routes"]["server"] + "/identity/isWorkingAtInTech",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identity_address: identity.address,
        providerUrl: provider,
      }),
    }
  ).then((res) => res.json());
  return rawResponse;
}

function createClaim(address, issuer, data, topic) {
  const claim = new IdentitySDK.Claim({
    address: address,
    data: data,
    issuer: issuer,
    emissionDate: Date.now(),
    scheme: 1,
    topic: parseInt(topic),
    uri: "",
  });
  return claim;
}

function createSelfAttestedClaim(address, data, topic) {
  return createClaim(address, address, data, topic);
}

async function createCustomSigner(signer) {
  return new IdentitySDK.SignerModule({
    publicKey: await signer.getAddress(),
    signMessage: signer.signMessage.bind(signer),
  });
}

async function getClaimId(address_issuer, topic) {
  const claimId = ethers.utils.keccak256(
    ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256"],
      [address_issuer, parseInt(topic)]
    )
  );
  if (claimId) {
    return claimId;
  } else {
    return;
  }
}

async function getSelfAttestedClaim(identity, topic) {
  return getClaim(identity, identity, topic);
}

async function getClaim(identity, issuer, topic) {
  const address_issuer = issuer.address;
  const claimId = await getClaimId(address_issuer, topic);

  if (claimId) {
    //The claims are auto-issued, it can only be one claim with this topic
    let claim = await identity.getClaim(claimId);
    return claim;
  } else {
    return;
  }
}

async function signClaim(claim, signer) {
  const claimSigned = await claim.sign(signer);
  return claimSigned;
}

async function prepareClaim(address, data, topic, signer) {
  let claim = createSelfAttestedClaim(address, data, topic);
  const customSigner = await createCustomSigner(signer);
  await signClaim(claim, customSigner);
  return claim;
}

async function sendClaimToBlockchain(identity, claim, signer) {
  await identity.addClaim(
    claim.topic,
    claim.scheme,
    claim.issuer,
    claim.signature,
    claim.data,
    claim.uri,
    { signer: signer }
  );
}

function hex2ascii(str1) {
  const hex = str1.toString();
  let str = "";
  let init = 0;

  if (str1.substring(0, 2) === "0x") {
    init = 2;
  }

  for (let n = init; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export {
  getClaim,
  getSelfAttestedClaim,
  sendClaimToBlockchain,
  prepareClaim,
  hex2ascii,
  isWorkingAtInTech,
};
