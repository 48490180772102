import { Identity, IdentitySDK } from "@onchain-id/identity-sdk";

import config from "../config" 

async function loadIdentity(identity_address, provider, signer) {
  const identity = await Identity.at(identity_address, signer);
  const keys = await identity.getKeysByPurpose(
    IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
  );
  for (const key of keys) {
    const hashedAddress = IdentitySDK.utils.encodeAndHash(
      ["address"],
      [await signer.getAddress()]
    );
    if (key.key === hashedAddress) {
      return identity;
    }
  }
}

async function getClaimIssuerAddresses() {
  return await (async () => {
    const rawResponse = await fetch(
      config["routes"]["server"] + "/claim-issuers",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const content = await rawResponse.json();
    return content;
  })();
}

export { loadIdentity, getClaimIssuerAddresses };
