import { ethers } from "ethers";
import React from "react";
import { withRouter } from "react-router-dom";

import config from "../config.js";
import store from "../store";
import Spinner from "../Spinner/Spinner";
import GoogleButton from "../Buttons/Google/GoogleButton";
import MicrosoftLogin from "../Buttons/Microsoft/MicrosoftLogin";
import FIDOLogin from "../Buttons/FIDO/FIDOLogin.js";
import MetaMaskLogin from "../Buttons/MetaMask/MetaMaskLogin.js";
import GitHubLogin from "../Buttons/GitHub/GitHubLogin.js";
import { getSelfAttestedClaim, isWorkingAtInTech } from "../modules/claim";
import { loadIdentity } from "../modules/identity";
import { hashStrInSha256 } from "../modules/utils";
import {
  validateJWTGoogle,
  validateJWTMicrosoft,
} from "../modules/validate-jwt";
import { sendPublicKeyCredentialAssertionToServer } from "../modules/fido2";

class Authenticate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      serverAllowedMethods: [],
      userAddedMethod: [],
      guideMode: false,
      waitingForAuth: false,
    };

    this.handleGitHubLogIn = this.handleGitHubLogIn.bind(this);
    this.handleFIDOLogIn = this.handleFIDOLogIn.bind(this);
    this.handleMetaMaskLogIn = this.handleMetaMaskLogIn.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  async componentDidMount() {
    this.provider =
      store.getState().provider ||
      new ethers.providers.Web3Provider(window.ethereum);
    this.signer = store.getState().signer || this.provider.getSigner();
    this.storedAddress = window.sessionStorage.getItem(
      config["session_storage_param"]["identity_address"]
    );
    this.identity = await loadIdentity(
      this.storedAddress,
      this.provider,
      this.signer
    );
    await this.getServerAllowedMethods();
    this.getUserAllowedMethods();

    this.unsubscribe = store.subscribe(() => {
      if (store.getState().guideMode !== this.state.guideMode) {
        this.setState({ guideMode: store.getState().guideMode });
      }
      if (
        store.getState().guideMode &&
        ((store.getState().helperState === "FIDO" &&
          store.getState().animationStep === 4) ||
          (store.getState().helperState === "Google" &&
            store.getState().animationStep === 3) ||
          (store.getState().helperState === "Microsoft" &&
            store.getState().animationStep === 4))
      ) {
        this.successfulAuthentication();
      }
    });
    store.dispatch({ type: "setHelperState", helperState: "authenticate" });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  async getServerAllowedMethods() {
    const methodsFromServer = await fetch(
      config["routes"]["server"] + "/auth/methods",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(async (response) => {
      return response.json();
    });
    const allowedMethods = Object.values(methodsFromServer.methods);

    this.setState({ serverAllowedMethods: allowedMethods });
  }

  getUserAllowedMethods() {
    this.state.serverAllowedMethods.forEach(async (method) => {
      const claimsId = await this.identity.getClaimIdsByTopic(method);
      if (claimsId.length > 0) {
        this.setState({ value: method });
        this.setState((state) => {
          const userAddedMethod = [...state.userAddedMethod, state.value];

          return {
            userAddedMethod,
            value: null,
          };
        });
      }
    });
  }

  async handleGoogleLogIn(response) {
    this.setState({ waitingForAuth: true });
    const ID_TOKEN = response.mc.id_token;

    if (await validateJWTGoogle(ID_TOKEN, config["google_client_id"])) {
      const googleId = response.Aa;

      const claim = await getSelfAttestedClaim(
        this.identity,
        config["topics"]["google"]
      );
      const hashGoogleId = hashStrInSha256(googleId);

      if (claim && claim.data === hashGoogleId) {
        if (store.getState().guideMode) {
          store.dispatch({
            type: "setAnimationStep",
            animationStep: store.getState().animationStep + 1,
          });
        } else {
          this.successfulAuthentication();
        }
      } else {
        this.setState({
          waitingForAuth: false,
          error: "Error while connecting. Thanks to retry later.",
        });
        this.initErrorState(3000);
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 10,
        });
      }
    }
  }

  async handleGitHubLogIn(id) {
    this.setState({ waitingForAuth: true });
    const claim = await getSelfAttestedClaim(
      this.identity,
      config["topics"]["github"]
    );
    const hashId = hashStrInSha256(id);
    if (claim && claim.data === hashId) {
      this.successfulAuthentication();
    } else {
      this.setState({ waitingForAuth: false });
      this.setState({
        error: "Error while connecting. Thanks to retry later.",
      });
      this.initErrorState(5000);
    }
  }

  async handleMicrosoftLogIn(response) {
    if (await validateJWTMicrosoft(response.idToken)) {
      this.setState({ waitingForAuth: true });
      const microsoftId = response.idTokenClaims.oid;

      const claim = await getSelfAttestedClaim(
        this.identity,
        config["topics"]["microsoft"]
      );
      const hashMicrosoftId = hashStrInSha256(microsoftId);

      if (claim && claim.data === hashMicrosoftId) {
        if (store.getState().guideMode) {
          store.dispatch({
            type: "setAnimationStep",
            animationStep: store.getState().animationStep + 1,
          });
        } else {
          this.successfulAuthentication();
        }
      } else {
        this.setState({
          waitingForAuth: false,
          error: "Error while connecting. Thanks to retry later.",
        });
        this.initErrorState(3000);
      }
    }
  }

  initErrorState(time) {
    setTimeout(() => this.setState({ error: "" }), time);
  }

  async successfulAuthentication() {
    let guideMode = store.getState().guideMode;
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    try {
      const providerUrl = config['providerUrl'][parseInt(chainId).toString()];
      if(!providerUrl) throw new Error('You are not connected to a compatible network');
      if ((await isWorkingAtInTech(this.identity, providerUrl)).isValid) {
        guideMode
          ? store.dispatch({ type: "setAnimationStep", animationStep: 9 })
          : this.props.history.push("success");
      } else {
        throw new Error("You don't work at InTech. The access is denied.");
      }
    } catch(error) {
      if (guideMode)
      store.dispatch({ type: "setAnimationStep", animationStep: 11 });
      this.setState({
        waitingForAuth: false,
        error: error.message,
      });
      this.initErrorState(3000);
    }
  }

  async handleFIDOLogIn(assertion) {
    this.setState({ waitingForAuth: true });
    try {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      const providerURL = config['providerUrl'][parseInt(chainId).toString()];
      if(!providerURL) throw new Error("You are not connected to a compatible network");
      const response = await sendPublicKeyCredentialAssertionToServer(
        assertion,
        this.storedAddress,
        providerURL
      );
      const isCorrectlySigned = response.isValid;
      if (isCorrectlySigned) {
        if (store.getState().guideMode) {
          store.dispatch({
            type: "setAnimationStep",
            animationStep: store.getState().animationStep + 1,
          });
        } else {
          this.successfulAuthentication();
        }
      }
      else {
        throw new Error("There has been a problem... Please retry later!");
      }
    } catch (error) {
      this.setState({
        waitingForAuth: false,
        error: error.message,
      });
      this.initErrorState(3000);
    }
  }

  async handleMetaMaskLogIn() {
    this.setState({ waitingForAuth: true });
    if (store.getState().guideMode) {
      store.dispatch({
        type: "setAnimationStep",
        animationStep: store.getState().animationStep + 1,
      });
    } else {
      this.successfulAuthentication();
    }
  }

  handleLoginFailure() {
    store.getState().guideMode &&
      store.dispatch({
        type: "setAnimationStep",
        animationStep: store.getState().animationStep + 2,
      });
    this.setState({
      waitingForAuth: false,
      error: "MetaMask authentification failed",
    });
    this.initErrorState(3000);
  }

  render() {
    return (
      <div className="h-screen text-center">
        {this.state.waitingForAuth && (
          <Spinner
            title="Wait a moment"
            text="We are checking your identity..."
          ></Spinner>
        )}
        <div className="m-auto max-w-2xl bg-white border-1 border-gray-300 p-6 tracking-wide shadow-lg">
          <div className="mb-10">
            <h1 className="my-3 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Authenticate with one of the methods available
            </h1>
          </div>
          <div className="m-10">
            <h2 className="my-7 text-xl font-semibold text-gray-700 dark:text-gray-200">
              Authentification methods that you allowed
            </h2>
            <div className="mx-auto w-2/5">
              {this.state.userAddedMethod.length === 0 && (
                <div className="m-2">
                  You didn't allowed any authentification method
                </div>
              )}
              {this.state.userAddedMethod.includes(
                config["topics"]["google"]
              ) && (
                <div className="mb-4">
                  <GoogleButton
                    className="w-full"
                    onLoggedIn={(response) => this.handleGoogleLogIn(response)}
                  />
                </div>
              )}
              {this.state.userAddedMethod.includes(
                config["topics"]["microsoft"]
              ) && (
                <div className="mb-4">
                  <MicrosoftLogin
                    msalConfig={config["microsoft_config"]}
                    onSuccess={(response) =>
                      this.handleMicrosoftLogIn(response)
                    }
                  />
                </div>
              )}
              {this.state.userAddedMethod.includes(
                config["topics"]["fido"]
              ) && (
                <div className="mb-4">
                  <FIDOLogin
                    userAddress={window.sessionStorage.getItem(
                      config["session_storage_param"]["identity_address"]
                    )}
                    onSuccess={this.handleFIDOLogIn}
                    onFailure={this.handleLoginFailure}
                  />
                </div>
              )}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["metamask"]
              ) && (
                <div className="mb-4">
                  <MetaMaskLogin
                    userAddress={window.sessionStorage.getItem(
                      config["session_storage_param"]["identity_address"]
                    )}
                    onSuccess={this.handleMetaMaskLogIn}
                    onFailure={this.handleLoginFailure}
                  />
                </div>
              )}
              {this.state.userAddedMethod.includes(
                config["topics"]["github"]
              ) && (
                <div className="mb-4">
                  <GitHubLogin onSuccess={this.handleGitHubLogIn} />
                </div>
              )}
            </div>
          </div>
          <div className="m-10">
            <h2 className="my-3 text-xl font-semibold text-gray-700 dark:text-gray-200">
              Authentification methods that we allow
            </h2>
            <div>
              {this.state.serverAllowedMethods.length === 0 && (
                <div className="m-2">
                  We didn't allow any authentification method
                </div>
              )}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["google"]
              ) && <div>Google Sign In</div>}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["microsoft"]
              ) && <div>Microsoft Sign In</div>}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["fido"]
              ) && <div>FIDO2</div>}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["metamask"]
              ) && <div>MetaMask</div>}
              {this.state.serverAllowedMethods.includes(
                config["topics"]["github"]
              ) && <div>GitHub</div>}
            </div>
          </div>
        </div>
        {this.state.error && (
          <div className="bg-red-300 border-l-4 border-red-700 text-red-600 max-w-xl mx-auto my-10 p-3 text-left leading-4">
            <p className="font-bold">Warning</p>
            <p>{this.state.error}</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Authenticate);
