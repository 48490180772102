import config from "../config" 

async function generateChallengeFromServer(address) {
  return await (async () => {
    const rawResponse = await fetch(
      config["routes"]["server"] + "/challenge/create",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: address,
        }),
      }
    );
    const content = await rawResponse.json();
    return content;
  })();
}

export { generateChallengeFromServer };
